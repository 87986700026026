import { AccessEnum, RolesEnum } from '@/app/auth/roles';

export const Accesses: Record<RolesEnum, AccessEnum[]> = {
  [RolesEnum.Administrators]: [...(Object.values(AccessEnum) as AccessEnum[])].filter(
    (elem) => elem != AccessEnum.DisabledAll
  ),
  [RolesEnum.Leader]: [
    AccessEnum.Drive,
    AccessEnum.CallCenterIncoming,
    AccessEnum.CallCenterResolved,
    AccessEnum.CalLCenterResolvedBashkortostan,
    AccessEnum.Map,
    AccessEnum.MapUspd,
    AccessEnum.Statistic,
    AccessEnum.PUManaging,
    AccessEnum.TasksBrowse,
    AccessEnum.Registry,
    AccessEnum.CastomPskClose,
    AccessEnum.Catalogs,
    AccessEnum.OnlyManagerAndLeadAndTeamManager,
    AccessEnum.CallingMapOperator,
    AccessEnum.ShowPPOStageChange,
    AccessEnum.CatalogsMeter,
    AccessEnum.DeliveryPointScreen,
    AccessEnum.ShowReports,
    AccessEnum.TeamManagerVoormanAndExecutor,
  ],
  [RolesEnum.ProjectManager]: [
    AccessEnum.Drive,
    AccessEnum.CallCenterIncoming,
    AccessEnum.CallCenterResolved,
    AccessEnum.CalLCenterResolvedBashkortostan,
    AccessEnum.Map,
    AccessEnum.MapUspd,
    AccessEnum.Statistic,
    AccessEnum.PUManaging,
    AccessEnum.TasksBrowse,
    AccessEnum.Registry,
    AccessEnum.CastomPskClose,
    AccessEnum.Catalogs,
    AccessEnum.OnlyManagerAndLeadAndTeamManager,
    AccessEnum.CallingMapOperator,
    AccessEnum.ShowPPOStageChange,
    AccessEnum.CatalogsMeter,
    AccessEnum.DeliveryPointScreen,
    AccessEnum.ShowReports,
    AccessEnum.TeamManagerVoormanAndExecutor,
  ],
  [RolesEnum.Tp]: [
    AccessEnum.CallCenterIncoming,
    AccessEnum.CallCenterResolved,
    AccessEnum.CalLCenterResolvedBashkortostan,
    AccessEnum.Map,
    AccessEnum.PUManaging,
    AccessEnum.Statistic,
    AccessEnum.TasksBrowse,
    AccessEnum.Registry,
    AccessEnum.CastomPskClose,
    AccessEnum.ShowPPOStageChange,
    AccessEnum.CatalogsMeter,
    AccessEnum.Catalogs,
    AccessEnum.ShowReports,
    AccessEnum.TeamManagerVoormanAndExecutor,
    AccessEnum.MapUspd,
  ],
  [RolesEnum.Designer]: [
    AccessEnum.CallCenterIncoming,
    AccessEnum.CallCenterResolved,
    AccessEnum.CalLCenterResolvedBashkortostan,
    AccessEnum.PUManaging,
    AccessEnum.Map,
    AccessEnum.Statistic,
    AccessEnum.TasksBrowse,
    AccessEnum.Registry,
    AccessEnum.CastomPskClose,
    AccessEnum.Catalogs,
    AccessEnum.Drive,
    AccessEnum.CallingMapOperator,
    AccessEnum.ShowPPOStageChange,
    AccessEnum.CatalogsMeter,
    AccessEnum.ShowReports,
    AccessEnum.TeamManagerVoormanAndExecutor,
  ],
  [RolesEnum.MesManager]: [
    AccessEnum.TasksBrowse,
    AccessEnum.CastomPskClose,
    AccessEnum.Map,
    AccessEnum.ShowPPOStageChange,
    AccessEnum.ShowReports,
  ],
  [RolesEnum.Voorman]: [
    AccessEnum.Map,
    AccessEnum.TasksBrowse,
    AccessEnum.Registry,
    AccessEnum.CastomPskClose,
    AccessEnum.PUManaging,
    AccessEnum.CallingMapOperator,
    AccessEnum.TeamManagerAndVoorman,
    AccessEnum.DeliveryPointScreen,
    AccessEnum.ShowReports,
    AccessEnum.TeamManagerVoormanAndExecutor,
    AccessEnum.ComplaintsPage,
    AccessEnum.ComplaintsEditTransferedToResponsible,
    AccessEnum.ComplaintsEditInProgress,
  ],
  [RolesEnum.TeamManager]: [
    AccessEnum.CallCenterResolved,
    AccessEnum.CalLCenterResolvedBashkortostan,
    AccessEnum.Map,
    AccessEnum.TasksBrowse,
    AccessEnum.Registry,
    AccessEnum.CastomPskClose,
    AccessEnum.PUManaging,
    AccessEnum.Catalogs,
    AccessEnum.OnlyManagerAndLeadAndTeamManager,
    AccessEnum.ShowPPOStageChange,
    AccessEnum.CatalogsMeter,
    AccessEnum.CallingMapOperator,
    AccessEnum.TeamManagerAndVoorman,
    AccessEnum.DeliveryPointScreen,
    AccessEnum.ShowReports,
    AccessEnum.TeamManagerVoormanAndExecutor,
  ],
  [RolesEnum.TeamManagerWithUSPDMap]: [
    AccessEnum.CallCenterResolved,
    AccessEnum.CalLCenterResolvedBashkortostan,
    AccessEnum.Map,
    AccessEnum.TasksBrowse,
    AccessEnum.Registry,
    AccessEnum.CastomPskClose,
    AccessEnum.PUManaging,
    AccessEnum.Catalogs,
    AccessEnum.OnlyManagerAndLeadAndTeamManager,
    AccessEnum.ShowPPOStageChange,
    AccessEnum.MapUspd,
    AccessEnum.CatalogsMeter,
    AccessEnum.ShowReports,
  ],
  [RolesEnum.Executor]: [
    AccessEnum.TasksBrowse,
    AccessEnum.CastomPskClose,
    AccessEnum.Map,
    AccessEnum.PUManaging,
    AccessEnum.DeliveryPointScreen,
    AccessEnum.ShowReports,
    AccessEnum.TeamManagerVoormanAndExecutor,
  ],
  [RolesEnum.MesManagerWithoutPhoto]: [
    AccessEnum.TasksBrowse,
    AccessEnum.CastomPskClose,
    AccessEnum.PUManaging,
    AccessEnum.Map,
    AccessEnum.ShowPPOStageChange,
  ],
  [RolesEnum.CallCenter]: [AccessEnum.CallCenterIncoming],
  [RolesEnum.CallCenterSec]: [
    AccessEnum.TasksBrowse,
    AccessEnum.Registry,
    AccessEnum.ComplaintsPage,
    AccessEnum.ComplaintsEditCallCenter,
    AccessEnum.ComplaintsCreateEnergyType,

    AccessEnum.notValidateComplaintSupAndEnergyType,
  ],
  [RolesEnum.CallCenterScreen]: [
    AccessEnum.CallCenterIncoming,
    AccessEnum.CallCenterResolved,
    AccessEnum.CalLCenterResolvedBashkortostan,
    AccessEnum.ShowReports,
  ],
  [RolesEnum.ManagerPsk]: [AccessEnum.TasksBrowse, AccessEnum.ShowPPOStageChange],
  [RolesEnum.Storehouse]: [AccessEnum.CatalogsMeter, AccessEnum.Storehouse, AccessEnum.ShowReports],
  [RolesEnum.StorehouseContractor]: [AccessEnum.Storehouse],

  [RolesEnum.StorehouseChief]: [
    AccessEnum.CatalogsMeter,
    AccessEnum.Storehouse,
    AccessEnum.StorehouseChief,
    AccessEnum.ShowReports,
  ],
  [RolesEnum.SupplyInfo]: [AccessEnum.StorehouseInfo, AccessEnum.SupplyInfoStatistic],
  [RolesEnum.StorehouseProject]: [AccessEnum.StorehouseInfo, AccessEnum.SupplyInfoStatistic],
  [RolesEnum.OwnerPerm]: [AccessEnum.TasksBrowse, AccessEnum.ShowPPOStageChange],
  [RolesEnum.TechConnectionViewer]: [AccessEnum.Drive, AccessEnum.ShowPPOStageChange],
  [RolesEnum.ContractorOperatorRole]: [
    AccessEnum.CallingMapOperator,
    AccessEnum.ShowPPOStageChange,
    AccessEnum.ShowReports,
  ],
  [RolesEnum.MesManagerWithPhoto]: [
    AccessEnum.TasksBrowse,
    AccessEnum.CastomPskClose,
    AccessEnum.ShowPPOStageChange,
    AccessEnum.ShowReports,
    AccessEnum.DisabledAll,
  ],
  [RolesEnum.CustomerViewerMrsk]: [AccessEnum.DeliveryPointScreen, AccessEnum.ShowReports],
  [RolesEnum.ManagerMesTo]: [AccessEnum.DeliveryPointScreen, AccessEnum.ShowReports],
  [RolesEnum.uspdMapOnly]: [AccessEnum.MapUspdOnly],

  [RolesEnum.ComplaintCustomer]: [
    AccessEnum.ComplaintsPage,
    AccessEnum.ComplaintsEditReturned,
    AccessEnum.ComplaintsEditTransferedToResponsible,
    AccessEnum.ComplaintsEditModeratedAfterResponsible,
    AccessEnum.ComplaintsEditInProgress,
  ],
  [RolesEnum.ComplaintManager]: [AccessEnum.ComplaintsPage, AccessEnum.ComplaintsEditAll],

  [RolesEnum.ComplaintEnergo]: [AccessEnum.ComplaintsPage, AccessEnum.ComplaintsCreateEnergyType],
  [RolesEnum.ComplaintOdpu]: [
    AccessEnum.ComplaintsPage,
    AccessEnum.ComplaintsEditOdpuModerated,
    AccessEnum.ComplaintsEditOdpuReturned,
    AccessEnum.ComplaintsCreateOdpuType,
  ],
  [RolesEnum.PPOODPUVisitor]: [],
  [RolesEnum.ReportForAudit]: [AccessEnum.ShowReports],
  [RolesEnum.MergeHouse]: [],
};
