import { useDisabledAccess } from '@/hooks/useAccess';
import { RolesEnum } from '@/app/auth/roles';

export const TASK_STATUS = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
  DISPUT: 'DISPUT',
  RETURNED: 'RETURNED',
  ASSIGNED: 'ASSIGNED',
  CHECK: 'CHECK',
  NOT_RELEVANT: 'NOT_RELEVANT',
  IN_WORK: 'IN_WORK',
};
export const useDisabledCloseButton = (taskType?: string) => {
  return useDisabledAccess({
    disabledRoles: [
      RolesEnum.Executor,
      RolesEnum.TeamManagerWithUSPDMap,
      // Кнопка раздизейблена для жалоб с типом  "Жалобы энерго сервис".
      // Если условия будут разрастаться - подумать над другой реализацией
      ...(taskType !== 'CLAIM_ENERGY_SERVICE' ? [RolesEnum.Voorman, RolesEnum.TeamManager] : []),
    ],
    refreshCondition: taskType,
  });
};
